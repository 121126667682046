<template>
  <div id="section1">
    <div class="fondoFwhite" :style="'background-image: url(' + payImg + ');'">  
      <b-container fluid>
        <b-container>
          <div class="HeadContainer">
            <div class="HeadVertical-center">
              <h1>
              {{$t("title")}}
              </h1>
            </div>
          </div>
        </b-container>
      </b-container>
    </div>      
  </div>
</template>

<i18n>
{
  "en":{

  },
  "es":{
    "title": "Conoce las últimas tendencias en aceptación de pagos."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/img_landing.png")
    }
  }
};
</script>
