<template>
  <div id="section2">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container>
          <br />
          <b-form>
              <b-row>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-input id="id-nombre" 
                      v-model="form.nombre" required
                      :placeholder="$t('payNombre')"/>
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-input id="id-apellido" 
                      v-model="form.apellido" required
                      :placeholder="$t('payApellido')"/>
                </b-col>
                <b-col md="4" sm="12" class="pb-2">
                  <b-form-input id="id-empresa" 
                      v-model="form.empresa" required
                      :placeholder="$t('payEmpresa')"/>
                </b-col>
                <b-col md="4" sm="12" class="pb-2">
                  <b-form-input id="id-telef" 
                      v-model="form.telef" required
                      :placeholder="$t('payTelef')"/>
                </b-col>
                <b-col md="4" sm="12" class="pb-2">
                  <b-form-input id="id-provincia" 
                      v-model="form.provincia" required
                      :placeholder="$t('payProvincia')"/>
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-input id="id-email" 
                      v-model="form.email" required
                      :placeholder="$t('payEmail')"/>
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-input id="id-emailC" 
                      v-model="form.emailC" required
                      :placeholder="$t('payEmailC')"/>
                </b-col>
                <b-col sm="12" >
                  <hr/>
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-select id="id-tipoSol"
                    v-model="form.tipoSol"
                    :options="payTipoSol"
                    name="tipoSol"
                    />
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-select id="id-facturacion"
                    v-model="form.facturacion"
                    :options="payFacturacion"
                    name="facturacion"
                    />
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-select id="id-tipoNeg"
                    v-model="form.tipoNeg"
                    :options="payListTipoNeg"
                    name="tipoNeg"
                    />
                </b-col>
                <b-col md="6" sm="12" class="pb-2">
                  <b-form-select id="id-cargo"
                    v-model="form.cargo"
                    :options="payCargo"
                    name="cargo"
                    />
                </b-col>
                <b-col sm="12" >
                  <hr/>
                </b-col>
                <b-col sm="12" class="pb-2">
                  <b-form-select id="id-conocido"
                    v-model="form.conocido"
                    :options="payConocido"
                    name="conocido"
                    />
                </b-col>
                <b-col sm="12" class="pb-2">
                  <br>
                  <b-form-checkbox id="id-acept" 
                      v-model="form.acept" 
                      >
                      {{$t("payAcept")}}
                  </b-form-checkbox>
                </b-col>
                <b-col sm="12" class="pb-2 text-center">
                  <br>
                  <b-button @click="enviarFormulario()" class="buttonAzul">
                    {{$t("payBtn")}}
                  </b-button>
                </b-col>
              </b-row>
              <br />
            </b-form>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "es":{
    "payNombre": "Nombres"
    , "payApellido": "Apellidos"
    , "payEmpresa": "Empresa"
    , "payTelef": "Teléfono Movil"
    , "payProvincia": "Provincia"
    , "payEmail": "E-mail"
    , "payEmailC": "Confirmar E-mail"
    , "payListTipoNeg": [
         { "value": "0"   , "text": "Seleccione Tipo de Negocio"}
        ,{ "value": "2131", "text": "Transporte de viajeros"}
        ,{ "value": "2133", "text": "Transporte urbano(terminal no atendido)"}
        ,{ "value": "2310", "text": "Venta de seguros (term.no atendidos)"}
        ,{ "value": "2399", "text": "Terminal de autoservicio postal"}
        ,{ "value": "2437", "text": "Donaciones beneficas ( t.n.a.)"}
        ,{ "value": "2453", "text": "Donaciones (terminales no atendidos)"}
        ,{ "value": "2722", "text": "Agencias de viajes(terminales no atendidos"}
        ,{ "value": "2912", "text": "Farmacias (tna)"}
        ,{ "value": "2941", "text": "Venta de entrada con terminales no atendidos"}
        ,{ "value": "2942", "text": "Libros, dicos y cd's (t.n.a.)"}
        ,{ "value": "2961", "text": "Ventas diferidas(term.no atendidos)"}
        ,{ "value": "2994", "text": "Suscripcion a publicacionees periodicas"}
        ,{ "value": "2995", "text": "Emision de certificados oficiales(t.n.a.)"}
        ,{ "value": "4131", "text": "Ferrocarriles de cercanias y autobuses urbanos"}
        ,{ "value": "4132", "text": "Ventas a bordo (mar)"}
        ,{ "value": "4133", "text": "Transporte urbano"}
        ,{ "value": "4214", "text": "Transportes mercancias,almacenamiento"}
        ,{ "value": "4411", "text": "Transporte viajeros ( via maritima )"}
        ,{ "value": "4457", "text": "Alquiler de embarcaciones de recreo"}
        ,{ "value": "4511", "text": "Lineas aereas"}
        ,{ "value": "4582", "text": "Aparcamiento y manten.de aeronaves"}
        ,{ "value": "4722", "text": "Agencias de viajes"}
        ,{ "value": "4784", "text": "Autopistas (terminales)"}
        ,{ "value": "4785", "text": "Autopistas y compra de bonos (manual)"}
        ,{ "value": "4789", "text": "Consignas automaticas"}
        ,{ "value": "4812", "text": "Cabinas telefonicas"}
        ,{ "value": "4813", "text": "Locutorios telefonicos"}
        ,{ "value": "4814", "text": "Venta de equipos telefonicos"}
        ,{ "value": "4829", "text": "Envio de dinero"}
        ,{ "value": "4900", "text": "Suministros"}
        ,{ "value": "5192", "text": "Kioscos"}
        ,{ "value": "5211", "text": "Materiales construccion,fontaneria,sanea, infraestructura"}
        ,{ "value": "5251", "text": "Ferreteria,bricolaje,menaje del hogar"}
        ,{ "value": "5311", "text": "Gran almacen"}
        ,{ "value": "5313", "text": "G.superficies de juguetes y deportes"}
        ,{ "value": "5331", "text": "Supermercados,ultramarinos, economatos"}
        ,{ "value": "5350", "text": "Ventas a domicilio"}
        ,{ "value": "5399", "text": "Consumos de emergencia"}
        ,{ "value": "5499", "text": "Superm.alimentacion manual"}
        ,{ "value": "5531", "text": "Menaje del hogar"}
        ,{ "value": "5533", "text": "Accesorios y repuestos de coches y maquinaria"}
        ,{ "value": "5541", "text": "Gasolineras"}
        ,{ "value": "5542", "text": "Venta de combustible a domicilio"}
        ,{ "value": "5611", "text": "Confeccion textil en general, moda"}
        ,{ "value": "5661", "text": "Zapaterias"}
        ,{ "value": "5681", "text": "Peleterias"}
        ,{ "value": "5712", "text": "Muebles,antiguedades y galerias de arte"}
        ,{ "value": "5714", "text": "Tapicerias,alfombras"}
        ,{ "value": "5722", "text": "Electrodomesticos,equipos electricos, tecnologÍa"}
        ,{ "value": "5732", "text": "Suscricpion a canales privados de tv"}
        ,{ "value": "5733", "text": "Instrumentos musicales"}
        ,{ "value": "5734", "text": "Venta de software"}
        ,{ "value": "5811", "text": "Restaurantes de 4 y 5 tenedores"}
        ,{ "value": "5812", "text": "Restaurantes menos de 4 tenedores"}
        ,{ "value": "5813", "text": "Cafeterias,snacks"}
        ,{ "value": "5814", "text": "Comida rapida"}
        ,{ "value": "5911", "text": "Droguerias, perfumerias"}
        ,{ "value": "5912", "text": "Farmacias"}
        ,{ "value": "5913", "text": "Herbolarios, plantas medicinales y parafarmacias"}
        ,{ "value": "5921", "text": "Venta bebidas alcoholicas, vinos"}
        ,{ "value": "5941", "text": "Marroquineria,articulos de viaje"}
        ,{ "value": "5942", "text": "Librerias, papelerias y discos"}
        ,{ "value": "5944", "text": "Joyerias y relojerias"}
        ,{ "value": "5945", "text": "Juguetes, articulos deportivos"}
        ,{ "value": "5946", "text": "Fotografia,equipos cine,optica,inst.medicas"}
        ,{ "value": "5947", "text": "Bazares, objetos regalo y souvenirs, sex shops"}
        ,{ "value": "5948", "text": "Piel(excl.peleterias)ante,napa y cuero"}
        ,{ "value": "5967", "text": "Contenido digital audio visual, cms, media entertainment"}
        ,{ "value": "5972", "text": "Filatelia y numismatica"}
        ,{ "value": "5992", "text": "Flores y plantas"}
        ,{ "value": "5993", "text": "Expendiduria oficial de tabaco"}
        ,{ "value": "5994", "text": "Suscripciones a publicaciones periodicas"}
        ,{ "value": "5995", "text": "Veterinaria (venta y cura de animales), tienda de mascotas"}
        ,{ "value": "5999", "text": "Heladeria"}
        ,{ "value": "6051", "text": "Casas de cambio"}
        ,{ "value": "6310", "text": "Seguros (compañias de )"}
        ,{ "value": "6513", "text": "Alquiler y venta de bienes inmuebles"}
        ,{ "value": "7011", "text": "4 y 5 estrellas: hoteles ,balnearios, etc."}
        ,{ "value": "7013", "text": "&lt; 4 estrellas: hoteles,moteles,balnearios,campings"}
        ,{ "value": "7015", "text": "Apartamentos y pisos multiusos"}
        ,{ "value": "7216", "text": "Tintorerias y lavanderias"}
        ,{ "value": "7231", "text": "Peluquerias,institutos de belleza y gimn"}
        ,{ "value": "7232", "text": "Masajes y saunas"}
        ,{ "value": "7251", "text": "Reparacion de calzado"}
        ,{ "value": "7261", "text": "Funerarias"}
        ,{ "value": "7273", "text": "Redes sociales de contacto"}
        ,{ "value": "7311", "text": "Agencias publicidad,gestorias,servicios, call centers, marketing y comunicaciÓn, pms"}
        ,{ "value": "7512", "text": "Alquiler automoviles"}
        ,{ "value": "7513", "text": "Alquiler de aeronaves"}
        ,{ "value": "7523", "text": "Garajes, aparcamientos"}
        ,{ "value": "7539", "text": "Autom.y motocicletas ( ventas y reparac)"}
        ,{ "value": "7542", "text": "Lavado de coches"}
        ,{ "value": "7941", "text": "Espectaculos y deportes, festivales"}
        ,{ "value": "7988", "text": "Casinos"}
        ,{ "value": "7994", "text": "Juegos"}
        ,{ "value": "7995", "text": "Apuestas"}
        ,{ "value": "7997", "text": "Estaciones de esqui"}
        ,{ "value": "7999", "text": "Clubs,salas fiesta,pubs,discotecas,bares"}
        ,{ "value": "8062", "text": "Hospitales,sanatorios y consultas medicas, salud"}
        ,{ "value": "8220", "text": "Colegios y centros de enseÑanza reglada"}
        ,{ "value": "8244", "text": "Colegios enseÑanza profesional/autoescuelas"}
        ,{ "value": "8411", "text": "Museos"}
        ,{ "value": "8412", "text": "Patrimonio nacional"}
        ,{ "value": "8911", "text": "Decoracion y arquitectura"}
        ,{ "value": "8999", "text": "Servicios profesionales no clasificados, cuponing, edificios inteligentes, integradores"}
        ,{ "value": "9399", "text": "Serv.org.publ.y grandes empresas"}
        ,{ "value": "9999", "text": "Otros" }
      ]
    , "payTipoSol": [
      "Seleccione Tipo de Solicitud de Alta"
      ,"TPV Virtual"
      ,"TPV Físico"
      ,"TPV Virtual + TPV Físico"
      ]
    , "payFacturacion": [
      "Seleccione Facturación Anual Estimada"
      ,"mq 10.000 €","mq 50.000 €"
      ,"mq 100.000 €","mq 100.000 €"
      ,"mq 1M €","mq 10M €","Mq 10M €"
      ]
    , "payCargo":[
      "Seleccione Cargo en la Empresa"
      ,"Director General"
      ,"Director / gerente financiero"
      ,"Director / gerente tecnología"
      ,"Director / gerente de ecommerce"
      ,"Director / gerente de negocio"
      ,"Otros: especificar"
      ]
    , "payConocido": [
      "¿Cómo nos has conocido?"
      ,"En un evento"
      ,"Recomendación de un amigo o conocido"
      ,"Buscando en internet","Banner / publicidad online"
      ,"Publicidad impresa"
      ,"A través de mi sucursal bancaria"
      ,"Otra"
    ]            
    , "payAcept": "He leído, doy mi consentimiento y acepto las Condiciones de uso y la Política de privacidad de Universalpay EP, SLU (incluido el tratamiento y la divulgación de mis datos personales). Entiendo que puedo cambiar mis preferencias de comunicación en cualquier momento."
    , "payBtn": "Empezar Ahora"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      img: require("@/assets/images/pay/logo_pay_color.png"),
      form: {
        nombre: ""
        , apellido: ""
        , empresa: "" 
        , telef: ""
        , provincia: ""
        , email: ""
        , emailC: ""
        , tipoSol: 0
        , facturacion: 0
        , tipoNeg: 0
        , cargo: 0
        , conocido: 0
        , acept: null
      }
    };
  },
  methods: {
    enviarFormulario(){
      console.log(this.form);
      let variant = 'default';
      this.$bvToast.toast('Enviado formulario ... ', {
          title: this.$t('message.pay.page4'),
          variant: variant,
          autoHideDelay: 3000,
          solid: true
        });
      this.form = {
        nombre: ""
        , apellido: ""
        , empresa: "" 
        , telef: ""
        , provincia: ""
        , email: ""
        , emailC: ""
        , tipoSol: 0
        , facturacion: 0
        , tipoNeg: 0
        , cargo: 0
        , conocido: 0
        , acept: null
      }  
    }
  },
  computed: {
    payListTipoNeg() {
      return this.$t("payListTipoNeg");
    },
    payTipoSol() {
      return this.$t("payTipoSol");
    },
    payFacturacion() {
      return this.$t("payFacturacion");
    },
    payCargo() {
      return this.$t("payCargo");
    },
    payConocido() {
      return this.$t("payConocido");
    }
  }
};
</script>
